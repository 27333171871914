import { Application } from "@hotwired/stimulus"

const application = Application.start()

if (RAILS_ENV === "development") {
  application.warnings = true
  application.debug    = true
  window.Stimulus      = application
}

import { layoutControllers } from "@uenf/layout/src/index"
for (const [name, controller] of Object.entries(layoutControllers)) {
  application.register(name, controller);
}

import { definitions } from "stimulus:./controllers"
application.load(definitions);

import AddressController from "@uenf/layout/src/js/controllers/extra/address_controller"
application.register("address", AddressController);

import AutocompleteController from "@uenf/layout/src/js/controllers/extra/autocomplete_controller";
application.register("autocomplete", AutocompleteController);